import './less/popup-container.less';

(function() {
    // close popup

    let close = document.getElementsByClassName('popup-container__cross')[0];
    let popup = document.getElementsByClassName('popup-container')[0];
    close.onclick = function() {
        popup.classList.remove('flex')
      };

})();