import '../blocks/style/index.js';
import '../fonts/fonts.js';
import '../blocks/wrapper/wrapper.js';
import '../blocks/container/container.js';
import '../blocks/btn/btn.js';
import '../blocks/link/link.js';
import '../blocks/hamburger-input/hamburger-input.js';
import '../blocks/popup-container/popup-container.js';

import '../blocks/body/body.js';

import '../blocks/header/header.js';
import '../blocks/f-b-phones/f-b-phones.js';
import '../blocks/menu/menu.js';
import '../blocks/header_class/header.js';
import '../blocks/navbar/navbar.js';
import '../blocks/hamburger-input-item/hamburger-input-item.js';
import '../blocks/hamburger-menu-button-transparent/hamburger-menu-button-transparent.js';
import '../blocks/hamburger-menu-button/hamburger-menu-button.js';
import '../blocks/logo/logo.js';
import '../blocks/menu-drop-down/menu-drop-down.js';
import '../blocks/content-header/content-header.js';
import '../blocks/call-form/call-form.js';
import '../blocks/main/main.js';
import '../blocks/main-section/main-section.js';

import '../blocks/advantages/advantages.js';
import '../blocks/advantages-item-block/advantages-item-block.js';

import '../blocks/price-icons-list/price-icons-list.js';
import '../blocks/price-icons-item/price-icons-item.js';
import '../blocks/price-caption/price-caption.js';

import '../blocks/header-section/header-section.js';
import '../blocks/price-article/price-article.js';
import '../blocks/article-item/article-item.js';
import '../blocks/price-list/price-list.js';


import '../blocks/main-header/main-header.js';
import '../blocks/footer/footer.js';
import '../blocks/f-section-call/f-section-call.js';
import '../blocks/f-section-bottom/f-section-bottom.js';
import '../blocks/solial-media/solial-media.js';
import '../blocks/b-404/b-404.js';