import './less/call-form.less';
import IMask from 'imask';
import '../../../node_modules/classlist.js/classList.min.js';

(function() {
    // input mask

    let phones = document.getElementsByClassName('call-form__phone-number');
    
    [].forEach.call(phones, p => {
        let phoneMask = new IMask(p, { mask: '+{7}(000)000-00-00' });
    })
})();

(function() {
    const axios = require('axios');
    let forms = document.getElementsByClassName('call-form');

    if (forms) {
        [].forEach.call(forms, f => {
            
            
            f.addEventListener('submit', e => {
                e.preventDefault();
                
            let phoneNumber = f.getElementsByTagName('input')[0].value;
            let button = f.getElementsByTagName('button')[0];
            let err_text = f.getElementsByClassName('call-form__error-text-header')[0];
            let inp_phone = f.getElementsByClassName('call-form__phone-number')[0];
            let popup = document.getElementsByClassName('popup-container')[0];
                
                var re = /^\+\d{1,3}\s?\(\d{3}\)\s?\d{3}(-\d{2}){2}$/.test(phoneNumber);

                if(re){
                    axios.post('/mailer.php', {
                        phone: phoneNumber
                    })
                    .then(response => {
                        if (response.data.success) {
                            err_text.classList.add('hidden');
                            inp_phone.classList.remove('input-error');
                            popup.classList.add('flex');
                            setTimeout(() => {popup.classList.remove('flex')}, 2500);
                        } else {
                            err_text.innerHTML = 'Ошибка сервера, попробуйте позже';
                            err_text.classList.add('visible');
                        }
                    })
                    .catch(error => {
                        err_text.innerHTML = 'Ошибка сервера, попробуйте позже';
                        err_text.classList.add('visible');
                    });
                }
                else{
                    inp_phone.classList.add('input-error');
                    err_text.innerHTML='номер введён неверно';
                    err_text.classList.add('visible');
                    err_text.classList.remove('hidden');
                }
            });
        });
    }
})()